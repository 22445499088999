import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../styles/articleView.module.css';

import { Article } from '../types';
import { RectItem } from './RectItem';
import { ListItem } from './ListItem';
import { FaList } from 'react-icons/fa';
import { MdPhotoLibrary } from 'react-icons/md';
import { useInfiniteQuery } from 'react-query';
import AdSense from 'react-adsense';
import { useAppContext } from '../ContextProvider';
import { ApiClient } from '../apiClient';
import Spinner from './Spinner';

interface ArticleViewProps {
	onInitialLoad?: () => void;
}

export const ArticleView: React.FC<ArticleViewProps> = ({ onInitialLoad }) => {
	const context = useAppContext();
	const client = new ApiClient(context);
	const [isGaleryView, setIsGaleryView] = useState(!context.isGaleryView());
	const [loading, setLoading] = useState(false);
	const initialLoadRef = useRef(false);

	useEffect(() => {
		setIsGaleryView(context.isGaleryView());
	}, [context]);

	const fetchArticles = async ({
		pageParam = 1,
	}): Promise<{
		articles: Article[];
		nextPage: number | null;
	}> => {
		try {
			context.setIsFetching(true);
			const articles = await client.listArticles(
				context.searchTerm,
				context.vendors,
				pageParam
			);

			return articles;
		} finally {
			context.setIsFetching(false);
		}
	};

	const { isLoading, isError, data, refetch, fetchNextPage } = useInfiniteQuery(
		['articles', context.searchTerm, context.vendors.join(',')],
		fetchArticles,
		{
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.nextPage;
			},
			staleTime: 30000,
			onSuccess: (data) => {
				if (!initialLoadRef.current && data.pages.length === 1) {
					initialLoadRef.current = true;
					onInitialLoad?.();
				}
			},
		}
	);

	const observer = useRef<IntersectionObserver>();
	const lastArticleElementRef = useCallback(
		(node: HTMLElement | null) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setLoading(true);
					fetchNextPage();
					setLoading(false);
				}
			});

			if (node) observer.current.observe(node);
		},
		[loading, fetchNextPage]
	);

	const articles = data?.pages?.map((p) => p.articles).flat() ?? [];

	const ImageView = useMemo(() => {
		return (
			<div className={styles['view']}>
				{articles?.map((article, index) => {
					if (articles.length === index + 1) {
						return (
							<RectItem
								ref={lastArticleElementRef}
								key={article._id}
								article={article}
							/>
						);
					} else {
						return <RectItem key={article._id} article={article} />;
					}
				}) ??
					((context.isFetching || isLoading) && <Spinner />)}
			</div>
		);
	}, [articles, context.isFetching, isLoading, lastArticleElementRef]);

	const ListView = useMemo(() => {
		return articles.map((article, index) => {
			// Check if it's the last element
			if (articles.length === index + 1) {
				return (
					<>
						<ListItem
							ref={lastArticleElementRef}
							key={article._id}
							article={article}
						/>
						{(context.isFetching || isLoading) && <Spinner />}
					</>
				);
			} else {
				return (
					<>
						<ListItem key={article._id} article={article} />
						{/* Display an ad after every 5th article */}
						{(index + 1) % 5 === 0 && (
							<AdSense.Google
								client="ca-pub-9555624941447246"
								slot="8441316935"
								style={{
									display: 'block',
									marginLeft: 100,
									marginRight: 80,
									height: 120,
								}}
								format="auto"
								responsive="true"
							/>
						)}
					</>
				);
			}
		});
	}, [articles, context.isFetching, isLoading, lastArticleElementRef]);

	const requestGaleryView = () => {
		setIsGaleryView(true);
		context.setView('galery');
	};

	const requestListView = () => {
		setIsGaleryView(false);
		context.setView('list');
	};

	return (
		<div className={styles['container']}>
			<div className={styles['options-bar']}>
				<p className={styles['result-count']}>
					{context.isFetching ? 'Loading...' : `${articles.length ?? 0} Result`}
					{context.isFetching ? '' : articles?.length === 1 ? '' : 's'}
				</p>

				<div className={styles['view-switch']}>
					{!isGaleryView ? (
						<MdPhotoLibrary onClick={requestGaleryView} />
					) : (
						<FaList onClick={requestListView} />
					)}
				</div>
			</div>

			{!isGaleryView ? ListView : ImageView}
		</div>
	);
};
