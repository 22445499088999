import clsx from 'clsx';
import { FaTimes } from 'react-icons/fa';
import { ModalProps } from './types';
import styles from '../../styles/modal/articleComment.module.css';
import { ApiClient } from '../../apiClient';
import { useAppContext } from '../../ContextProvider';
import { useCallback, useEffect, useState } from 'react';
import { ArticleComment } from '../../types';

export const ArticleCommentsModal: React.FC<ModalProps> = ({
	onClose,
	modalMeta,
}) => {
	const context = useAppContext();
	const [comments, setComments] = useState<ArticleComment[]>([]);
	const [message, setMessage] = useState<string>();

	const client = new ApiClient(context);

	const onInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setMessage(e.target.value);
		},
		[]
	);

	const handleSendComment = useCallback(async () => {
		if (!message) return;

		const articleId = modalMeta.articleId;

		await client.postComment(articleId, message);

		setComments([
			...comments,
			{
				userId: context.getUserInfo()!.id,
				userName: context.getUserInfo()!.name,
				message,
				createdAt: new Date().toDateString(),
			},
		]);

		setMessage('');
	}, [message, modalMeta.articleId, client, context, comments]);

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			handleSendComment();
		},
		[handleSendComment]
	);

	const handleKeyPress = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				handleSendComment();
			}
		},
		[handleSendComment]
	);

	const fetchMessages = useCallback(async () => {
		const fetchedComments = await client.listComments(modalMeta.articleId);

		setComments(fetchedComments);
	}, [modalMeta.articleId, client]);

	useEffect(() => {
		fetchMessages();
	}, []);

	const title =
		modalMeta.articleTitle?.substr(0, 40) +
		(modalMeta.articleTitle?.length > 40 ? '...' : '');

	return (
		<>
			<div className="modal-header">
				<p>{title}</p>
				<span className="modal-close" onClick={onClose}>
					<FaTimes />
				</span>
			</div>
			<div className={clsx('modal-body')}>
				<div className={styles['comment-view']}>
					{[...comments].reverse().map((c, i) => (
						<div key={c.message + i} className={styles['comment']}>
							<p className={styles['comment-created-at']}>
								{new Date(c.createdAt).toLocaleDateString()}
							</p>
							<p className={styles['comment-author']}>{c.userName}</p>
							<p className={styles['comment-message']}>{c.message}</p>
						</div>
					))}
				</div>
			</div>
			<div className="modal-footer">
				<div className={styles['input-container']}>
					<input
						type="text"
						className={clsx(styles['comment-input'])}
						onChange={onInputChange}
						onKeyPress={handleKeyPress}
						value={message}
					/>
					<button onClick={handleClick} className={styles['btn']}>
						Send
					</button>
				</div>
			</div>
		</>
	);
};
