import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { UserInfo, useAppContext } from '../ContextProvider';
import { FaGoogle } from 'react-icons/fa';
import styles from '../styles/googleLoginButton.module.css';
import { forwardRef, useImperativeHandle } from 'react';

export interface GoogleLoginButtonProps {
	onLogin?: (userInfo: UserInfo) => void;
}

interface GoogleLoginButtonRef {
	click: () => void;
}

export const GoogleLoginButton = forwardRef<
	GoogleLoginButtonRef,
	GoogleLoginButtonProps
>((props, ref) => {
	const { signin, getUserInfo, setIsFetching } = useAppContext();

	useImperativeHandle(ref, () => ({
		click: () => login(),
	}));

	const login = useGoogleLogin({
		flow: 'auth-code',
		redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
		onSuccess: async (codeResponse) => {
			setIsFetching(true);
			const res = await axios
				.get(
					`${process.env.REACT_APP_SERVER_URL}/oauth/callback?code=${codeResponse.code}`
				)
				.finally(() => {
					setIsFetching(false);
				});

			const token = res.data.token;

			delete res.data.token;

			signin(token, res.data);

			const userInfo = getUserInfo();

			if (userInfo) props.onLogin?.(userInfo);
		},
		onError: (errorResponse) => console.log(errorResponse),
	});

	return <FaGoogle onClick={login} className={styles['google-signin']} />;
});
