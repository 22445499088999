import { useEffect, Suspense, lazy, useState } from 'react';
import { ArticleView, Header, SideBar } from './components';
import 'react-tooltip/dist/react-tooltip.css';
import GA from 'react-ga4';
import { ContextProvider } from './ContextProvider';
import Modal from 'react-modal';
import './App.css';

Modal.setAppElement('#root');

GA.initialize(process.env.REACT_APP_TRACKING_ID!);

function SplashScreen() {
	return (
		<div className="splash-screen">
			<img src="/pressco-icon.png" alt="Pressco Icon" />
		</div>
	);
}

function App() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		GA.send({ hitType: 'pageview', page: window.location.href });
	}, []);

	const handleInitialLoad = () => {
		setLoading(false);
	};

	return (
		<ContextProvider>
			{loading && <SplashScreen />}
			<div className="App">
				<Header />
				<SideBar />
				<ArticleView onInitialLoad={handleInitialLoad} />
			</div>
		</ContextProvider>
	);
}

export default App;
