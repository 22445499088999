import { FaTimes } from 'react-icons/fa';
import { ModalProps } from './types';
import { ApiClient } from '../../apiClient';
import { useAppContext } from '../../ContextProvider';

export const ArticlePingModal: React.FC<ModalProps> = (props) => {
	const context = useAppContext();

	return (
		<>
			<div className="modal-header">
				<p title={props.modalMeta.articleTitle}>
					{props.modalMeta.articleTitle?.length > 40
						? props.modalMeta.articleTitle.substr(0, 40) + '...'
						: props.modalMeta.articleTitle}
				</p>
				<span className="modal-close" onClick={props.onClose}>
					<FaTimes />
				</span>
			</div>
			<div className="modal-body">
				<form
					onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
						e.preventDefault();
						const email = (e.target as any)['ping'].value;

						if (!email) return;

						const articleId = props.modalMeta.articleId;

						const client = new ApiClient(context);

						client.ping(articleId, email).then(() => props.onClose());
					}}
				>
					<label className="ping-label" htmlFor="ping">
						Ping article to:
					</label>
					<input
						required
						id="ping"
						name="ping"
						type="email"
						className="ping-input"
						placeholder="some@email.com"
					/>
					<button>Ping</button>
				</form>
			</div>
			<div className="modal-footer"></div>
		</>
	);
};
