import { useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { GoogleLoginButton } from '../GoogleLoginButton';
import { ModalProps } from './types';

export const LoginRequiredModal: React.FC<ModalProps> = (props) => {
	const googleButtonRef = useRef<HTMLButtonElement>(null);

	const handlePClick = () => {
		if (googleButtonRef.current) {
			googleButtonRef.current.click();
		}
	};

	return (
		<>
			<div className="modal-header">
				<span className="modal-close" onClick={props.onClose}>
					<FaTimes />
				</span>
			</div>
			<div className="modal-body">
				<p>You need to be logged in to perform this action.</p>
				<div className="login-container">
					<p className="info pointer" onClick={handlePClick}>
						Login with google:
					</p>{' '}
					<GoogleLoginButton ref={googleButtonRef} onLogin={props.onClose} />
				</div>
			</div>
			<div className="modal-footer"></div>
		</>
	);
};
