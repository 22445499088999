import { FaTimes } from 'react-icons/fa';
import { ModalProps } from './types';

export const ErrorModal: React.FC<ModalProps> = (props) => {
	return (
		<>
			<div className="modal-header">
				<span className="modal-close" onClick={props.onClose}>
					<FaTimes />
				</span>
			</div>
			<div className="modal-body">
				<p>Failed to perform this action.</p>
				{props.modalMessage && <p className="error">{props.modalMessage}</p>}
			</div>
			<div className="modal-footer"></div>
		</>
	);
};
