import React, {
	createContext,
	useContext,
	PropsWithChildren,
	useState,
	ChangeEvent,
} from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import _ from 'lodash';
import { Vendors } from './types';
import { Modal, ModalEvents } from './components';

export interface UserInfo {
	id: string;
	name: string;
	picture: string;
}

export interface Context {
	signin: (token: string, userInfo: UserInfo) => void;
	signout: () => void;
	getUserInfo: () => UserInfo | undefined;
	openModal: (
		modalEvent: ModalEvents,
		message?: string,
		meta?: Record<string, any>
	) => void;
	closeModal: () => void;
	isLoggedIn: () => boolean;
	getToken: () => string | null;
	setIsFetching: (flag: boolean) => void;
	isFetching: boolean;
	setSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void;
	setVendor: (vendor: Vendors) => void;
	vendors: Vendors[];
	searchTerm?: string;
	setView: (view: 'galery' | 'list') => void;
	isGaleryView: () => boolean;
}

export const Context = createContext<Context>({} as Context);

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [modalEvent, setModalEvent] = useState<ModalEvents | null>();
	const [modalMessage, setModalMessage] = useState<string | null>();
	const [modalMeta, setModalMeta] = useState<Record<string, any>>({});
	const [searchTerm, setSearchTerm] = useState<string>();
	const [vendors, setVendors] = useState<Vendors[]>([]);
	const signin = (token: string, userInfo: UserInfo) => {
		localStorage.setItem('token', token);
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
	};

	const signout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('userInfo');
	};

	const getUserInfo = (): UserInfo | undefined => {
		try {
			const userInfo = localStorage.getItem('userInfo');

			if (!userInfo) return;

			return JSON.parse(userInfo);
		} catch (_) {
			return undefined;
		}
	};

	const openModal = (
		modalEvent: ModalEvents,
		message?: string,
		meta?: Record<string, any>
	) => {
		setIsModalOpen(true);
		setModalEvent(modalEvent);
		setModalMessage(message);
		if (meta) setModalMeta(meta);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setModalEvent(null);
		setModalMessage(null);
		setModalMeta({});
	};

	const isLoggedIn = (): boolean => {
		const token = localStorage.getItem('token');

		return !!token;
	};

	const getToken = (): string | null => {
		const token = localStorage.getItem('token');

		return token;
	};

	const isGaleryView = (): boolean => {
		const view = localStorage.getItem('view');
		return view === 'galery';
	};

	const setView = (view: 'galery' | 'list'): void => {
		localStorage.setItem('view', view);
	};

	const setVendor = (vendor: Vendors): void => {
		if (vendors.includes(vendor))
			setVendors(vendors.filter((v) => v !== vendor));
		else setVendors([...vendors, vendor]);

		return;
	};

	return (
		<Context.Provider
			value={{
				signin,
				signout,
				getUserInfo,
				openModal,
				closeModal,
				isLoggedIn,
				getToken,
				setIsFetching: (flag: boolean) => setIsFetching(flag),
				isFetching,
				setSearchTerm: _.debounce((event: ChangeEvent<HTMLInputElement>) => {
					const { value } = event.target;
					setSearchTerm(value);
				}, 300),
				searchTerm,
				setVendor,
				vendors,
				setView,
				isGaleryView,
			}}
		>
			<GoogleOAuthProvider clientId="692228653851-gf2mk6kl19aap68k2284p29ntvk9ib18.apps.googleusercontent.com">
				{children}
				<Modal
					isOpen={isModalOpen}
					modalEvent={modalEvent}
					onClose={closeModal}
					modalMessage={modalMessage}
					modalMeta={modalMeta}
				/>
			</GoogleOAuthProvider>
		</Context.Provider>
	);
};

export function useAppContext() {
	return useContext(Context);
}
