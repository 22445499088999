import { FaSpinner } from 'react-icons/fa';
import styles from '../styles/spinner.module.css';

const Spinner = () => {
	return (
		<div className={styles.container}>
			<FaSpinner className={styles.spinner} />
		</div>
	);
};

export default Spinner;
