import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import styles from '../styles/header.module.css';
import { UserInfo, useAppContext } from '../ContextProvider';
import { GoogleLoginButton } from './GoogleLoginButton';
import clsx from 'clsx';

export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = (props) => {
	const context = useAppContext();
	const [userInfo, setUserInfo] = useState<UserInfo>();
	const { signout, getUserInfo, isFetching } = useAppContext();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const userInfo = getUserInfo();
		setUserInfo(userInfo);

		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [getUserInfo, isFetching]);

	const triggerSignout = () => {
		signout();
		setUserInfo(undefined);
	};

	return (
		<div className={styles['header']}>
			<div
				className={clsx(styles['logo-holder'], {
					[styles['logo-holder-icon']]: !(windowWidth > 768),
				})}
			>
				{windowWidth > 768 ? (
					<img
						src="/pressco.png"
						alt="Pressco Logo"
						className={styles['logo-long']}
					/>
				) : (
					<img
						src="/pressco-icon.png"
						alt="Pressco Icon"
						className={styles['logo-icon']}
					/>
				)}
			</div>
			<div className={styles['search']}>
				<AiOutlineSearch className={styles['search-icon']} />
				<input type="text" onChange={context.setSearchTerm} />
			</div>
			<div className={styles['options']}>
				{!userInfo ? (
					<GoogleLoginButton onLogin={(userInfo) => setUserInfo(userInfo)} />
				) : (
					<div className={styles['profile-holder']}>
						<img onClick={triggerSignout} src={userInfo.picture} alt="" />
					</div>
				)}
			</div>
		</div>
	);
};
