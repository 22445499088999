import { forwardRef, useEffect, useState, MouseEvent, useRef } from 'react';
import styles from '../styles/rectItem.module.css';
import { Article } from '../types';
import { useAppContext } from '../ContextProvider';
import { ApiClient } from '../apiClient';
import clsx from 'clsx';
import {
	FaArrowUp,
	FaFacebookF,
	FaPaperPlane,
	FaComment,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { ResponsiveText } from './ResponsiveText';
import moment from 'moment';
import { ModalEvents } from './Modal';
import GA from 'react-ga4';

interface RectItemProps {
	article: Article;
}

export const RectItem = forwardRef<HTMLAnchorElement, RectItemProps>(
	(props, ref) => {
		const [article, setArticle] = useState<Article>(props.article);
		const context = useAppContext();

		useEffect(() => {
			setArticle(props.article);
		}, [props.article]);

		const marqueeRef = useRef<HTMLParagraphElement>(null);
		const descriptionRef = useRef<HTMLDivElement>(null);

		useEffect(() => {
			const marqueeElement = marqueeRef.current;
			const descriptionElement = descriptionRef.current;

			if (marqueeElement && descriptionElement) {
				const marqueeWidth = marqueeElement.offsetWidth;
				const animationDuration = marqueeWidth / 30;

				marqueeElement.style.animationDuration = `${animationDuration}s`;
			}
		}, []);

		const urlImage =
			props.article.image ??
			`/assets/${props.article.source.toLowerCase()}.png`;

		const onThumbUpClick = async (e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			if (!context.isLoggedIn())
				return context.openModal(ModalEvents.LOGIN_REQUIRED);

			const client = new ApiClient(context);

			try {
				await client.thumbsUpArticleById(article._id);

				const userRating = article.ratings?.userRating === 1 ? 0 : 1;

				article.ratings = {
					...(article?.ratings
						? {
								upvotes: userRating
									? article.ratings.upvotes + 1
									: article.ratings.upvotes - 1,
								downvotes: article.ratings.downvotes,
						  }
						: {
								upvotes: 1,
								downvotes: 0,
						  }),
					userRating,
				};

				setArticle({ ...article });
			} catch (e: any) {
				context.openModal(ModalEvents.FAILED_ACTION, e.message);
			}
		};

		const rating = article.ratings?.upvotes ?? 0;

		const onPingFriend =
			(articleId: string, articleTitle: string) =>
			(e: MouseEvent<HTMLButtonElement>) => {
				e.preventDefault();
				if (!context.isLoggedIn())
					return context.openModal(ModalEvents.LOGIN_REQUIRED);

				context.openModal(ModalEvents.PING_FRIEND, undefined, {
					articleId,
					articleTitle,
				});
			};

		const onComment =
			(articleId: string, articleTitle: string) =>
			(e: MouseEvent<HTMLButtonElement>) => {
				e.preventDefault();
				if (!context.isLoggedIn())
					return context.openModal(ModalEvents.LOGIN_REQUIRED);

				context.openModal(ModalEvents.ARTICLE_COMMENT, undefined, {
					articleId,
					articleTitle,
				});
			};

		const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			GA.event({
				category: 'Link Click',
				action: 'Clicked',
				label: props.article.url,
			});
		};

		const onTwitterShare = (e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			window.open(
				`https://twitter.com/intent/tweet?url=${encodeURIComponent(
					article.url
				)}`,
				'_blank',
				'noopener,noreferrer'
			);
			GA.event({
				category: 'Link Click',
				action: 'Clicked',
				label: article.url,
			});
		};

		const onFacebookShare = (e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			window.open(
				`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
					article.url
				)}`,
				'_blank',
				'noopener,noreferrer'
			);
			GA.event({
				category: 'Link Click',
				action: 'Clicked',
				label: article.url,
			});
		};

		return (
			<a
				ref={ref}
				className={styles['container']}
				href={props.article.url}
				target="_blank"
				rel="noreferrer"
				onClick={handleClick}
			>
				<div className={styles['icon-holder']}>
					<img
						src={`/assets/${props.article.source.toLowerCase()}.png`}
						alt=""
					/>
				</div>
				<div
					className={styles['img-container']}
					style={{
						backgroundImage: `url(${urlImage})`,
					}}
				></div>
				<div className={styles['title-holder']}>
					{article.description && (
						<div
							title={article.description}
							className={styles['description']}
							ref={descriptionRef}
						>
							<p className={styles['marquee']} ref={marqueeRef}>
								{article.description.trim()}
							</p>
						</div>
					)}
					<h3 className={styles['title']}>
						<ResponsiveText
							charWidthInPx={12}
							isGrid={true}
							text={article.ogTitle ?? article.title}
						/>
					</h3>
				</div>
				<div className={styles['bullets']}>
					{(article.createdAt || article.updatedAt) && (
						<p>
							{moment
								.utc(article.createdAt ?? article.updatedAt)
								.local()
								.format('MMM Do YYYY, hh:mm A')}
						</p>
					)}
				</div>
				<div className={styles['actions']}>
					<div className={styles['share-buttons']}>
						<button className={styles['share-btn']} onClick={onTwitterShare}>
							<FaXTwitter />
						</button>
						<button className={styles['share-btn']} onClick={onFacebookShare}>
							<FaFacebookF />
						</button>
						<button
							className={styles['share-btn']}
							onClick={onPingFriend(article._id, article.title)}
						>
							<FaPaperPlane />
						</button>
					</div>
					<div className={styles['article-actions']}>
						<button
							className={clsx([styles['action-btn'], styles['comment-btn']])}
							onClick={onComment(article._id, article.title)}
						>
							<div className={styles['comment-icon-container']}>
								<FaComment />
								{article.commentCount && article.commentCount > 0 ? (
									<span className={styles['comment-count']}>
										{article.commentCount}
									</span>
								) : null}
							</div>
						</button>
						<button
							className={clsx([
								styles['action-btn'],
								styles['rate-btn'],
								{
									[styles['i-upvoted-it']]: article.ratings?.userRating === 1,
								},
							])}
							onClick={onThumbUpClick}
						>
							<FaArrowUp />
						</button>
						{rating ? (
							<p
								className={clsx({
									[styles['i-upvoted-it']]: rating > 0,
								})}
							>
								{rating}
							</p>
						) : null}
					</div>
				</div>
			</a>
		);
	}
);
