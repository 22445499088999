import moment from 'moment';
import { forwardRef, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import {
	FaArrowUp,
	FaFacebookF,
	FaComment,
	FaPaperPlane,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import GA from 'react-ga4';
import { Article } from '../types';
import styles from '../styles/listItem.module.css';
import { ResponsiveText } from './ResponsiveText';
import { useAppContext } from '../ContextProvider';
import { ApiClient } from '../apiClient';
import clsx from 'clsx';
import { ModalEvents } from './Modal';

export interface ListItemProps {
	article: Article;
}

export const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
	(props, ref) => {
		const [article, setArticle] = useState<Article>(props.article);
		const context = useAppContext();

		useEffect(() => {
			setArticle(props.article);
		}, [props.article]);

		const [show, setShow] = useState<boolean>(false);

		const handleClick = (
			event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
		) => {
			const href = event.currentTarget.getAttribute('href');

			if (href) {
				GA.event({
					category: 'Link Click',
					action: 'Clicked',
					label: href,
				});
			}
		};

		const onThumbUpClick = async () => {
			if (!context.isLoggedIn())
				return context.openModal(ModalEvents.LOGIN_REQUIRED);

			const client = new ApiClient(context);

			try {
				await client.thumbsUpArticleById(article._id);

				const userRating = article.ratings?.userRating === 1 ? 0 : 1;

				article.ratings = {
					...(article?.ratings
						? {
								upvotes: userRating
									? article.ratings.upvotes + 1
									: article.ratings.upvotes - 1,
								downvotes: article.ratings.downvotes,
						  }
						: {
								upvotes: 1,
								downvotes: 0,
						  }),
					userRating,
				};

				setArticle({ ...article });
			} catch (e: any) {
				context.openModal(ModalEvents.FAILED_ACTION, e.message);
			}
		};

		const onPingFriend = (articleId: string, articleTitle: string) => () => {
			if (!context.isLoggedIn())
				return context.openModal(ModalEvents.LOGIN_REQUIRED);

			context.openModal(ModalEvents.PING_FRIEND, undefined, {
				articleId,
				articleTitle,
			});
		};

		const onComment = (articleId: string, articleTitle: string) => () => {
			if (!context.isLoggedIn())
				return context.openModal(ModalEvents.LOGIN_REQUIRED);

			context.openModal(ModalEvents.ARTICLE_COMMENT, undefined, {
				articleId,
				articleTitle,
			});
		};

		const rating = article.ratings?.upvotes ?? 0;

		return (
			<div ref={ref} className={styles['container']}>
				<div
					key={article.title}
					className={styles['article']}
					onClick={() => setShow(!show)}
				>
					{article.image && (
						<Tooltip
							className={styles['tooltip']}
							id={`my-tooltip-${article.title}`}
						>
							<img src={article.image} alt="" />
						</Tooltip>
					)}
					<div className={styles['title-holder']}>
						<div className={styles['link-holder']}>
							<a
								data-tooltip-id={`my-tooltip-${article.title}`}
								href={article.url}
								rel="noreferrer"
								target="_blank"
								onClick={handleClick}
							>
								<ResponsiveText text={article.ogTitle ?? article.title} />
							</a>
						</div>
					</div>
					<div className={styles['description']}>
						{(article.description || article.content) && (
							<ResponsiveText
								charWidthInPx={7.5}
								text={
									article.description ?? article.content!.replace(/\s+/g, ' ')
								}
							/>
						)}
					</div>
					<div className={styles['bullets']}>
						{(article.createdAt || article.updatedAt) && (
							<p>
								{moment
									.utc(article.createdAt ?? article.updatedAt)
									.local()
									.format('MMMM Do YYYY, dddd, HH:mm A')}
							</p>
						)}
						{article.authors && (
							<p className={styles['author']}>| {article.authors}</p>
						)}
					</div>
				</div>
				<div className={styles['actions']}>
					<div className={styles['icon-holder']}>
						<img
							src={`/assets/${article.source.toLowerCase()}.png`}
							alt=""
							title={article.source}
						/>
					</div>
					<div className={styles['share']}>
						<a
							className={clsx([styles['action-btn'], styles['share-btn']])}
							href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
								article.url
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaXTwitter />
						</a>
						<a
							className={clsx([styles['action-btn'], styles['share-btn']])}
							href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								article.url
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaFacebookF />
						</a>
						<button
							className={clsx([styles['action-btn'], styles['share-btn']])}
							onClick={onPingFriend(article._id, article.title)}
						>
							<FaPaperPlane />
						</button>
					</div>
					<button
						className={clsx(
							[styles['action-btn']],
							clsx([styles['comment-btn']])
						)}
						onClick={onComment(article._id, article.title)}
					>
						<div className={styles['comment-icon-container']}>
							<FaComment />
							{article.commentCount && article.commentCount > 0 ? (
								<span className={styles['comment-count']}>
									{article.commentCount}
								</span>
							) : null}
						</div>
					</button>
					<div className={styles['rate']}>
						<div
							className={clsx(styles['rate-icon'], {
								[styles['i-upvoted-it']]: article.ratings?.userRating === 1,
							})}
							onClick={onThumbUpClick}
						>
							<FaArrowUp />
						</div>
						{rating ? (
							<p
								className={clsx({
									[styles['i-upvoted-it']]: rating > 0,
								})}
							>
								{rating}
							</p>
						) : null}
					</div>
				</div>
			</div>
		);
	}
);
