export interface Article {
	_id: string;
	title: string;
	ogTitle: string;
	url: string;
	createdAt: string;
	source: string;
	updatedAt?: string;
	keywords?: string;
	image?: string;
	description?: string;
	content?: string;
	ratings?: { upvotes: number; downvotes: number; userRating?: number };
	authors?: string;
	commentCount?: number;
}

export enum Vendors {
	BLIC = 'Blic',
	KURIR = 'Kurir',
	POLITIKA = 'Politika',
	TELEGRAF = 'Telegraf',
	N1 = 'N1',
	NOVA = 'Nova',
	VOA = 'VOA',
	DW = 'DW',
	ALJAZEERA = 'Aljazeera',
	TANJUG = 'Tanjug',
	RT = 'RT',
	VREME = 'Vreme',
	MONDO = 'Mondo',
	Nin = 'NIN',
	PESCANIK = 'Pescanik',
}

export interface ArticleComment {
	userId: string;
	userName: string;
	message: string;
	createdAt: string;
}
