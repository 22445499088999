import clsx from 'clsx';
import { useMemo } from 'react';
import ReactModal from 'react-modal';
import { ModalEvents, ModalProps } from './types';
import { LoginRequiredModal } from './LoginRequiredModal';
import { ErrorModal } from './ErrorModal';
import { ArticlePingModal } from './ArticlePingModal';
import { ArticleCommentsModal } from './ArticleCommentsModal';

export * from './types';

export const Modal: React.FC<ModalProps> = (props) => {
	const ModalContent = useMemo(() => {
		switch (props.modalEvent) {
			case ModalEvents.LOGIN_REQUIRED:
				return <LoginRequiredModal {...props} />;
			case ModalEvents.FAILED_ACTION:
				return <ErrorModal {...props} />;
			case ModalEvents.PING_FRIEND:
				return <ArticlePingModal {...props} />;
			case ModalEvents.ARTICLE_COMMENT:
				return <ArticleCommentsModal {...props} />;
		}
	}, [props]);

	return (
		<ReactModal
			className={clsx('modal-container', {
				'error-modal': props.modalEvent === ModalEvents.FAILED_ACTION,
				'modal-lg': [ModalEvents.ARTICLE_COMMENT].includes(props.modalEvent!),
			})}
			isOpen={props.isOpen}
			shouldCloseOnOverlayClick={true}
			style={{
				overlay: {
					backgroundColor: 'rgba(0, 0, 0, 0.75)',
				},
			}}
		>
			{ModalContent}
		</ReactModal>
	);
};
