import clsx from 'clsx';
import { useEffect, useState } from 'react';

export const ResponsiveText: React.FC<{
	text: string;
	charWidthInPx?: number;
	className?: string;
	isGrid?: boolean;
}> = (props) => {
	const [truncatedText, setTruncatedText] = useState('');
	const charWidthInPx = props.charWidthInPx ?? 8.5;

	function truncateStringBasedOnScreenWidth(
		inputString: string,
		charWidthInPx: number,
		screenWidth: number
	) {
		const maxChars = Math.floor(screenWidth / charWidthInPx);
		return inputString.length > maxChars
			? inputString.substring(0, maxChars - 3) + '...'
			: inputString;
	}

	useEffect(() => {
		const handleResize = () => {
			setTruncatedText(
				truncateStringBasedOnScreenWidth(
					props.text,
					charWidthInPx,
					window.innerWidth *
						(!props.isGrid
							? 1
							: (window.innerWidth > 998
									? 0.3
									: window.innerWidth > 642
									? 0.45
									: 1) * 2.5)
				)
			);
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [charWidthInPx, props.charWidthInPx, props.text]);

	return <>{truncatedText}</>;
};
