import { useAppContext } from '../ContextProvider';
import styles from '../styles/sideBar.module.css';
import { Vendors } from '../types';
import { VendorSelect } from './VendorSelect';

export interface SideBarProps {}

export const SideBar: React.FC<SideBarProps> = () => {
	const context = useAppContext();
	return (
		<div className={styles['sidebar']}>
			{Object.values(Vendors).map((v) => (
				<VendorSelect
					key={v}
					vendor={v}
					handleClick={context.setVendor}
					isSelected={context.vendors.includes(v) || !context.vendors.length}
				/>
			))}
		</div>
	);
};
