export enum ModalEvents {
	LOGIN_REQUIRED,
	FAILED_ACTION,
	PING_FRIEND,
	ARTICLE_COMMENT,
	DEFAULT,
}

export interface ModalProps {
	isOpen: boolean;
	modalEvent: ModalEvents | null | undefined;
	modalMessage: string | null | undefined;
	modalMeta: Record<string, any>;
	onClose: () => void;
}
