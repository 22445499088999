import { Vendors } from '../types';

export interface VendorSelectProps {
	vendor: Vendors;
	handleClick: (vendor: Vendors) => void;
	isSelected: boolean;
}

export const VendorSelect: React.FC<VendorSelectProps> = ({
	vendor,
	isSelected,
	handleClick,
}) => {
	return (
		<div className="vendor-filter" onClick={() => handleClick(vendor)}>
			<img
				style={!isSelected ? { filter: 'blur(1px) grayscale(100%)' } : {}}
				src={`/assets/${vendor.toLowerCase()}.png`}
				alt=""
			/>
		</div>
	);
};
